define("workflows-web/workflows/details/edit/actions/details/code-editor-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "F30QEZPc",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"code-editor-field\"],[15,\"data-label\",[34,0,[\"label\"]]],[12],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"field\",\"class\",\"choices\",\"updateFieldValue\"],[[35,0],\"hf-u-pull-right\",[35,2],[30,[36,1],[[32,0],\"insertTags\"],null]]]]],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"field\"],[[35,0]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"field\",\"action\",\"fieldChoices\",\"components/insert-tags\",\"form-field\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/details/code-editor-field/template.hbs"
    }
  });
});