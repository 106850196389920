define("workflows-web/components/dynamic-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: 'hf-dynamic-field',
    classNameBindings: ['isOpen'],
    value: '',
    showMergeFieldsText: Ember.computed('field.{isDynamic,type}', 'isDynamicField', {
      get: function get() {
        return this.get('field.isDynamicField') || this.get('field.type') === 'dynamic' || this.get('isDynamicField');
      }
    }),
    actions: {
      setInputComponentReference: function setInputComponentReference(inputComponent) {
        this.set('inputComponent', inputComponent);
      },
      openDropdown: function openDropdown() {
        this.set('isOpen', true);
        if (this.get('actionOnOpen')) {
          var field = this.field,
            customFieldData = this.customFieldData;
          var choices = field.choices;
          if (!choices) {
            field.set('choices', Ember.A());
          }
          if (!field.actionOnOpenPerformed) {
            this.get('actionOnOpen').perform(field, '', 'actionOnOpen', customFieldData);
          }
        }
      },
      closeDropdown: function closeDropdown(data, valueProperty) {
        this.set('isOpen', false);
        if (data) {
          this.get('inputComponent').updateField(data[valueProperty]);
        }
        if (this.get('actionOnClose')) {
          this.get('actionOnClose')(data);
        }
      },
      updateFieldValue: function updateFieldValue(data, valueProperty) {
        this.get('inputComponent').updateField(data[valueProperty]);
      },
      toggleDropdown: function toggleDropdown() {
        if (!this.get('isDestroyed')) {
          if (this.get('isOpen')) {
            this.send('closeDropdown');
          } else {
            this.send('openDropdown');
          }
        }
      },
      onFocusOut: function onFocusOut(updatedValue, selectedOption) {
        if (this.onFocusOut) {
          this.onFocusOut(updatedValue, selectedOption);
        }
      },
      onValueChange: function onValueChange(updatedValue, selectedOption) {
        if (this.onValueChange) {
          this.onValueChange(updatedValue, selectedOption);
        }
      }
    }
  });
});