define("workflows-web/components/insert-tags/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    isOpen: false,
    actions: {
      toggleDropdown: function toggleDropdown() {
        this.toggleProperty('isOpen');
      },
      closeDropdown: function closeDropdown() {
        this.set('isOpen', false);
      },
      updateFieldValue: function updateFieldValue(value, choiceValueProperty) {
        this.updateFieldValue(value, choiceValueProperty);
      }
    }
  });
});