define("workflows-web/components/dynamic-field/dropdown-component/option-value/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    isExpanded: false,
    hasOverflow: false,
    didRender: function didRender() {
      this._super.apply(this, arguments);
      var optionValueElement = this.element.querySelector('.hf-dynamic-field_dropdown-option-value');
      if (optionValueElement) {
        this.set('hasOverflow', optionValueElement.scrollWidth > optionValueElement.clientWidth);
      }
    },
    actions: {
      toggleExpanded: function toggleExpanded() {
        this.toggleProperty('isExpanded');
        return false;
      }
    }
  });
});