define("workflows-web/initializers/modify-basic-dropdown", ["exports", "jquery", "ember-basic-dropdown/components/basic-dropdown"], function (_exports, _jquery, _basicDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'modify-basic-dropdown',
    initialize: function initialize() {
      _basicDropdown.default.reopen({
        // In a form when pressing enter, submit button should be activated and the currently focussed power select should not open.
        handleKeydown: function handleKeydown(e) {
          if (e.keyCode === 13) {
            // Prevent opening dropdown on Enter
            return;
          }
          this._super(e);
        },
        // This is to prevent the dropdown from closing when we click on the input field of the dynamic field.
        close: function close(e, skipFocus) {
          if (this.get('isDestroyed')) {
            return;
          }

          /**
           * When the dynamic field dropdown is opened,
           * we need to prevent it from closing when the user clicks on the input field
           * of that particular dynamic field.
           */
          var mergeFieldInputLabel = (0, _jquery.default)(e.target).closest('[data-label]').attr('data-label');
          var mergeFieldDropdownLabel = (0, _jquery.default)(this.element).closest('[data-label]').attr('data-label');
          if (mergeFieldInputLabel && mergeFieldDropdownLabel && mergeFieldInputLabel === mergeFieldDropdownLabel) {
            return;
          }
          var publicAPI = this.get('publicAPI');
          if (publicAPI.disabled || !publicAPI.isOpen) {
            return;
          }
          var onClose = this.get('onClose');
          if (onClose && onClose(publicAPI, e) === false) {
            return;
          }
          if (this.get('isDestroyed')) {
            return;
          }
          this.setProperties({
            hPosition: null,
            vPosition: null,
            top: null,
            left: null,
            right: null,
            width: null,
            height: null,
            previousVerticalPosition: null,
            previousHorizontalPosition: null
          });
          this.updateState({
            isOpen: false
          });
          if (skipFocus) {
            return;
          }
          var trigger = document.querySelector("[data-ebd-id=".concat(publicAPI.uniqueId, "-trigger]"));
          if (trigger && trigger.tabIndex > -1) {
            trigger.focus();
          }
        }
      });
    }
  };
});