define("workflows-web/helpers/truncate-normal-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DISPLAY_LIMIT = 26;
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    var _params$;
    var displayLimit = Ember.isPresent(params[1]) ? params[1] : DISPLAY_LIMIT;
    if (((_params$ = params[0]) === null || _params$ === void 0 ? void 0 : _params$.length) > displayLimit) {
      return "".concat(params[0].substring(0, displayLimit), "...");
    } else {
      return params[0];
    }
  });
});