define("workflows-web/components/add-filter-condition/component", ["exports", "ember-buffered-proxy/proxy", "uuid", "workflows-web/mixins/filters/add-filter-condition", "workflows-web/utilities/date", "workflows-web/utilities/time"], function (_exports, _proxy, _uuid, _addFilterCondition, _date, _time) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TIME_UNIT_CHOICES = Ember.A([Ember.Object.create({
    id: 'm'
  }), Ember.Object.create({
    id: 'h'
  }), Ember.Object.create({
    id: 'd'
  })]);
  var customFieldsFilterFieldsMap = {
    'dropdown': {
      type: 'dynamic',
      predicates: ['in', 'not_in', 'set', 'not_set']
    },
    'multiple_option': {
      type: 'dynamic',
      predicates: ['in', 'not_in', 'set', 'not_set']
    },
    'text': {
      type: 'dynamic',
      predicates: ['equals', 'not_equals', 'set', 'not_set']
    },
    'textarea': {
      type: 'dynamic',
      predicates: ['equals', 'not_equals', 'set', 'not_set']
    },
    'number': {
      type: 'dynamic',
      predicates: ['equals', 'not_equals', 'set', 'not_set', 'lt', 'lte', 'gt', 'gte']
    },
    'date': {
      type: 'dynamic',
      predicates: ['equals', 'not_equals', 'set', 'not_set', 'lte', 'gte']
    }
  };
  var assetCustomFieldsFilterFieldsMap = {
    'dropdown': {
      type: 'dynamic',
      predicates: ['in']
    },
    'multiple_option': {
      type: 'dynamic',
      predicates: ['in']
    },
    'textarea': {
      type: 'dynamic',
      predicates: ['equals']
    },
    'text': {
      type: 'dynamic',
      predicates: ['equals']
    },
    'number': {
      type: 'dynamic',
      predicates: ['equals']
    },
    'date': {
      type: 'dynamic',
      predicates: ['equals']
    }
  };
  var rangePredicateKeys = ['lt', 'lte', 'gt', 'gte'];
  var _default = _exports.default = Ember.Component.extend(_addFilterCondition.default, {
    canOpenPredicateDropdown: false,
    canOpenValueDropdown: false,
    condition: null,
    conditionUnitChoices: null,
    init: function init() {
      var _this = this;
      var conditionUnitChoices = TIME_UNIT_CHOICES;
      conditionUnitChoices.forEach(function (unit) {
        unit.set('text', _this.get('intlService').findTranslationByKey("automate.".concat(unit.id)));
      });
      this.set('conditionUnitChoices', conditionUnitChoices);
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this.$('.hf-js-filter-condition-field-input .ember-power-select-trigger').focus();
    },
    initializeAddFilterBuffer: function initializeAddFilterBuffer() {
      var intlService = this.intlService;
      var selectedField = this.get('selectedField');
      if (Ember.typeOf(selectedField) === 'instance' && (selectedField.key === 'custom_fields' || selectedField.isCustomField)) {
        var conditionField = this.condition.field;
        var _customFieldsFilterFi = customFieldsFilterFieldsMap[conditionField.type],
          type = _customFieldsFilterFi.type,
          predicates = _customFieldsFilterFi.predicates;
        if (selectedField.key === 'asset_custom_fields' && conditionField.type) {
          var _assetCustomFieldsFil;
          predicates = (_assetCustomFieldsFil = assetCustomFieldsFilterFieldsMap[conditionField.type]) === null || _assetCustomFieldsFil === void 0 ? void 0 : _assetCustomFieldsFil.predicates;
        }
        Ember.set(selectedField, 'type', type);
        Ember.set(selectedField, 'predicates', predicates);
        Ember.set(selectedField, 'uniqueId', "".concat((0, _uuid.v4)()));
        selectedField.set('customField', Ember.Object.create({
          name: conditionField.name
        }));
        this.extra.setActionDetailsChoices.perform(selectedField).then(function () {
          if (Ember.isPresent(conditionField.options)) {
            selectedField.choices.unshiftObject({
              groupName: "".concat(conditionField.name, " Choices"),
              options: conditionField.options.map(function (option) {
                option.label = option.value;
                return option;
              })
            });
          }
        });
      }
      if (this.get('selectedField.key') === 'due_date') {
        var selectedPredicate = this.get('selectedPredicate.value');
        if (Ember.isPresent(this.get('selectedFromDate')) && Ember.isPresent(this.get('selectedToDate')) && selectedPredicate !== 'not_set' && selectedPredicate !== 'set') {
          this.set('selectedPredicate', {
            label: intlService.findTranslationByKey('filters.equals'),
            value: 'range'
          });
          this.set('selectedValue', {
            label: intlService.findTranslationByKey('between'),
            value: 'between'
          });
        } else if (Ember.isPresent(this.get('selectedFromDate')) && selectedPredicate !== 'not_set' && selectedPredicate !== 'set') {
          this.set('selectedPredicate', {
            label: intlService.findTranslationByKey("filters.".concat(this.get('selectedPredicate.value'))),
            value: this.get('selectedPredicate.value')
          });
          this.set('selectedValue', {
            label: intlService.findTranslationByKey('on'),
            value: 'on'
          });
        }
      }
      var filterCondition = Ember.Object.create({
        field: selectedField,
        predicate: this.get('selectedPredicate'),
        value: this.get('selectedValue'),
        fromDate: this.get('selectedFromDate'),
        toDate: this.get('selectedToDate')
      });
      if (this.get('selectedField.type') === 'timeWithUnits') {
        var conditionUnitChoices = this.get('conditionUnitChoices');
        var conditionUnit = this.get('condition.unit');
        if (conditionUnit) {
          var choice = conditionUnitChoices.findBy('id', conditionUnit);
          filterCondition.set('unit', choice);
        }
      }
      var filterConditionBuffer = _proxy.default.create({
        content: filterCondition
      });
      this.set('filterConditionBuffer', filterConditionBuffer);
      if (selectedField) {
        if (selectedField.key !== 'due_date') {
          var predicateOptions = this.get('predicateOptions'); // for the sake of powerselect during edit.
          filterConditionBuffer.set('predicate', predicateOptions.findBy('value', this.get('selectedPredicate.value')));
        }
        filterConditionBuffer.applyBufferedChanges();
      }
    },
    isTimeFieldWithUnits: Ember.computed.equal('filterConditionBuffer.field.type', 'timeWithUnits'),
    predicateOptions: Ember.computed('filterConditionBuffer.field', 'condition.field', {
      get: function get() {
        var selectedField = this.get('filterConditionBuffer.field.key');
        var _this$get = this.get('filterConditionBuffer.field'),
          predicates = _this$get.predicates;
        if (predicates) {
          var _this$get2 = this.get('filterConditionBuffer.field'),
            type = _this$get2.type;
          var intlService = this.get('intlService');
          var predicateOptions;
          if (selectedField === 'tags' || type === 'text') {
            predicateOptions = predicates.map(function (fieldPredicate) {
              return {
                value: fieldPredicate,
                label: intlService.findTranslationByKey("filters.tags.".concat(fieldPredicate))
              };
            });
          } else if (selectedField === 'assets') {
            predicateOptions = predicates.map(function (fieldPredicate) {
              return {
                value: fieldPredicate,
                label: intlService.findTranslationByKey("filters.assets.".concat(fieldPredicate))
              };
            });
          } else if (selectedField === 'agent_scripts') {
            predicateOptions = predicates.map(function (fieldPredicate) {
              return {
                value: fieldPredicate,
                label: intlService.findTranslationByKey("filters.agent-scripts.".concat(fieldPredicate))
              };
            });
            // FIXME: This is a generic component. Don't have module specific checks here!!!
          } else {
            if (type === 'date' || type === 'timestamp') {
              predicateOptions = predicates.map(function (fieldPredicate) {
                return {
                  value: fieldPredicate,
                  label: intlService.findTranslationByKey("filters.date.".concat(fieldPredicate))
                };
              });
            } else {
              predicateOptions = predicates.map(function (fieldPredicate) {
                return {
                  value: fieldPredicate,
                  label: intlService.findTranslationByKey("filters.".concat(fieldPredicate))
                };
              });
            }
          }
          return predicateOptions;
        }
      },
      set: function set(key, val) {
        return val;
      }
    }),
    showAdditionalDatePicker: Ember.computed('filterConditionBuffer.{predicate,value}', {
      get: function get() {
        var selectedField = this.get('filterConditionBuffer.field');
        var selectedPredicate = this.get('filterConditionBuffer.predicate');
        var selectedValue = this.get('filterConditionBuffer.value');
        if (selectedField.key === 'due_date') {
          return selectedValue.value === 'between';
        } else {
          return selectedPredicate.value === 'between';
        }
      },
      set: function set(key, val) {
        return val;
      }
    }),
    showCustomFieldAutoCompleteDropdown: Ember.computed('condition.field', {
      get: function get() {
        var condition = this.condition;
        // Custom field can also have a different key name, need to check that as well
        var isCustomField = this.get('filterConditionBuffer.field.isCustomField');
        if (condition && Ember.typeOf(condition) === 'instance') {
          return condition.field.key === 'custom_fields' || isCustomField;
        }
      },
      set: function set(key, val) {
        return val;
      }
    }),
    isDueDateCustom: Ember.computed('filterConditionBuffer.{value,predicate}', {
      get: function get() {
        var field = this.get('filterConditionBuffer.field');
        var predicate = this.get('filterConditionBuffer.predicate');
        var value = this.get('filterConditionBuffer.value');
        if (Ember.isPresent(value)) {
          return field.key === 'due_date' && (value.value === 'on' || value.value === 'between') && predicate.value !== 'not_set' && predicate.value !== 'set';
        }
      }
    }),
    actions: {
      addCondition: function addCondition() {
        var filterConditionBuffer = this.get('filterConditionBuffer');
        var selectedField = filterConditionBuffer.get('field');
        var selectedFieldKey = selectedField.key;
        var selectedPredicate = filterConditionBuffer.get('predicate');
        var selectedPredicateKey, selectedValue, selectedUnit;
        if (this.get('showPredicateList')) {
          selectedPredicateKey = selectedPredicate.value;
        }
        if (Ember.isPresent(filterConditionBuffer.get('value'))) {
          selectedValue = filterConditionBuffer.get('value');
        }
        var selectedFromDate = filterConditionBuffer.get('fromDate');
        var selectedToDate = filterConditionBuffer.get('toDate');
        /* eslint-disable no-self-assign */
        if (selectedPredicateKey !== 'not_set' && selectedPredicateKey !== 'set') {
          if (selectedFieldKey == 'contact_group_id') {
            selectedValue = selectedValue.id;
          } else if (selectedFieldKey === 'due_date') {
            if (Ember.isPresent(selectedFromDate) && selectedValue.value === 'on') {
              // custom due date with predicate is and value on
              if (Ember.isPresent(selectedToDate)) {
                this.set('selectedToDate', null);
              }
              if (selectedPredicateKey !== 'not_equals') {
                selectedPredicateKey = 'equals';
              }
              selectedValue = (0, _date.convertDateToApiFormat)(selectedFromDate);
            } else if (Ember.isPresent(selectedFromDate) && Ember.isPresent(selectedToDate) && selectedValue.value === 'between') {
              // custom due date with predicate is and value between
              selectedPredicateKey = 'range';
              selectedValue = {
                'gte': (0, _date.convertDateToApiFormat)(selectedFromDate),
                'lte': (0, _date.convertDateToApiFormat)(selectedToDate)
              };
            } else {
              // due date with values like today,tomorrow,next 7 days,yesterday
              if (selectedPredicateKey === 'range') {
                selectedPredicateKey = 'equals';
              }
              selectedValue = selectedValue.value;
              /* when a due date value is edited from custom date to either of today,tomorrow,next 7 days,yesterday
                   the previously selected dates need to be cleared */
              if (Ember.isPresent(selectedFromDate)) {
                this.set('selectedFromDate', null);
              }
              if (Ember.isPresent(selectedToDate)) {
                this.set('selectedToDate', null);
              }
            }
          } else if (selectedFieldKey === 'created_at' || selectedFieldKey === 'last_staff_reply_at' || selectedFieldKey === 'last_contact_reply_at' || selectedFieldKey === 'last_updated_at' || selectedFieldKey === 'last_modified_at') {
            selectedFromDate = (0, _date.createDate)(filterConditionBuffer.get('fromDate'));
            if (Ember.isPresent(filterConditionBuffer.get('toDate'))) {
              selectedToDate = (0, _date.createDate)(filterConditionBuffer.get('toDate'));
              if (selectedFromDate.getTime() === selectedToDate.getTime()) {
                selectedToDate = (0, _time.getEndOfDay)(selectedFromDate);
              }
            }
          } else if (selectedFieldKey === 'custom_fields' || selectedField.isCustomField) {
            var customField;
            if (this.isEdit) {
              customField = this.condition.field;
            } else {
              customField = filterConditionBuffer.get('field.customField');
            }
            if (customField.type === 'multiple_option' || customField.type === 'dropdown') {
              selectedValue = selectedValue.split(',').map(function (value) {
                return value.trim();
              });
            }
            customField.key = selectedFieldKey;
            selectedFieldKey = customField;
          } else if (selectedField.type === 'local-single-autocomplete') {
            selectedValue = selectedValue.value;
          } else if (selectedField.type === 'boolean') {
            selectedValue = selectedValue.value;
          } else if (selectedField.type === 'date') {
            if (selectedPredicateKey === 'equals') {
              selectedValue = 'today';
            } else {
              selectedValue = Number(selectedValue);
            }
          } else if (selectedField.type === 'number') {
            selectedValue = Number(selectedValue);
          } else if (this.get('isTimeFieldWithUnits')) {
            selectedValue = Number(selectedValue);
            selectedUnit = filterConditionBuffer.get('unit.id');
          } else if (selectedField.type === 'local-multiple-autocomplete') {
            selectedValue = selectedValue.map(function (option) {
              // NOTE: Having Conditional check here, because Smart Rules Action type does not have id.
              return isNaN(Number(option.value)) ? option.value : Number(option.value);
            });
          } else if (selectedField.type === 'remote-single-autocomplete') {
            selectedValue = Ember.isPresent(selectedValue.id) ? selectedValue.id : selectedValue;
          } else if (selectedField.type === 'text' || selectedField.type === 'remote-multiple-autocomplete') {
            selectedValue = selectedValue;
          } else if (selectedField.type === 'dynamic') {
            if (selectedField.allowMultipleValues && !rangePredicateKeys.includes(selectedPredicateKey)) {
              selectedValue = selectedValue.split(',').map(function (value) {
                return value.trim();
              });
            } else {
              selectedValue = selectedValue;
            }
          } else if (selectedFieldKey === 'has_breached_sla' || selectedFieldKey === 'has_attachments' || selectedFieldKey === 'unresponded') {
            selectedPredicateKey = 'equals';
            selectedValue = true;
          } else {
            selectedValue = selectedValue.mapBy(this.get('optionValuePath'));
          }
        }
        switch (selectedPredicateKey) {
          case 'lt':
            selectedPredicateKey = 'range';
            selectedValue = {
              'lt': selectedValue || (0, _time.getAPIFormatTime)((0, _time.getStartOfDay)(selectedFromDate))
            };
            break;
          case 'lte':
            selectedPredicateKey = 'range';
            selectedValue = {
              'lte': selectedValue || (0, _time.getAPIFormatTime)((0, _time.getEndOfDay)(selectedFromDate))
            };
            break;
          case 'gt':
            selectedPredicateKey = 'range';
            selectedValue = {
              'gt': selectedValue || (0, _time.getAPIFormatTime)((0, _time.getEndOfDay)(selectedFromDate))
            };
            break;
          case 'gte':
            selectedPredicateKey = 'range';
            selectedValue = {
              'gte': selectedValue || (0, _time.getAPIFormatTime)((0, _time.getStartOfDay)(selectedFromDate))
            };
            break;
          case 'on':
            selectedPredicateKey = 'range';
            selectedValue = {
              'gte': (0, _time.getAPIFormatTime)((0, _time.getStartOfDay)(selectedFromDate)),
              'lte': (0, _time.getAPIFormatTime)((0, _time.getEndOfDay)(selectedFromDate))
            };
            break;
          case 'between':
            selectedPredicateKey = 'range';
            selectedValue = {
              'gte': (0, _time.getAPIFormatTime)((0, _time.getStartOfDay)(selectedFromDate)),
              'lte': (0, _time.getAPIFormatTime)((0, _time.getEndOfDay)(selectedToDate))
            };
            break;
        }
        if (selectedField.type === 'dynamic' && selectedField.allowMultipleValues && selectedPredicateKey === 'range' && !rangePredicateKeys.includes(selectedPredicateKey)) {
          selectedValue = [selectedValue];
        }
        /* eslint-enable no-self-assign */
        if (!this.get('isEdit')) {
          var condition = Ember.Object.create({
            field: selectedFieldKey,
            predicate: selectedPredicateKey,
            value: selectedValue,
            unit: selectedUnit
          });
          this.addCondition(condition);
        } else {
          this.updateCondition(selectedFieldKey, selectedPredicateKey, selectedValue, selectedUnit);
        }
        this.get('popOver').closePopOver();
      },
      setSelectedField: function setSelectedField(field) {
        if (field.key === 'custom_fields' || field.isCustomField) {
          this.set('showCustomFieldAutoCompleteDropdown', true);
        } else {
          this.set('showCustomFieldAutoCompleteDropdown', false);
          var predicateOptions = this.get('predicateOptions');
          if (Ember.isPresent(predicateOptions)) {
            this.set('filterConditionBuffer.predicate', predicateOptions.get('firstObject'));
          }
          if (this.get('valueType') === 'date' && this.get('filterConditionBuffer.predicate.value') === 'equals') {
            this.set('filterConditionBuffer.value', 'today');
          } else {
            this.set('filterConditionBuffer.value', null);
          }
          if (this.get('isTimeFieldWithUnits') && !this.get('condition.unit')) {
            this.get('filterConditionBuffer').set('unit', this.get('conditionUnitChoices')[0]);
          }
        }
      },
      setSelectedPredicate: function setSelectedPredicate(selectedPredicate) {
        this.set('filterConditionBuffer.predicate', selectedPredicate);
        if (this.get('valueType') === 'date' && this.get('filterConditionBuffer.predicate.value') === 'equals') {
          this.set('filterConditionBuffer.value', 'today');
        } else {
          this.set('filterConditionBuffer.value', null);
        }
      },
      setCanOpenPredicateDropdown: function setCanOpenPredicateDropdown() {
        if (!this.get('isEdit')) {
          this.set('canOpenPredicateDropdown', true);
        }
      },
      setCanOpenValueDropdown: function setCanOpenValueDropdown() {
        if (!this.get('isEdit')) {
          this.set('canOpenValueDropdown', true);
        }
      },
      onCustomFieldChange: function onCustomFieldChange(selectedCustomField) {
        var _this2 = this;
        var _customFieldsFilterFi2 = customFieldsFilterFieldsMap[selectedCustomField.type],
          type = _customFieldsFilterFi2.type,
          predicates = _customFieldsFilterFi2.predicates;
        var selectedField = this.get('filterConditionBuffer.field.key');
        if (selectedField === 'asset_custom_fields') {
          var _assetCustomFieldsFil2;
          predicates = (_assetCustomFieldsFil2 = assetCustomFieldsFilterFieldsMap[selectedCustomField.type]) === null || _assetCustomFieldsFil2 === void 0 ? void 0 : _assetCustomFieldsFil2.predicates;
        }
        var customField = Ember.Object.create({
          name: selectedCustomField.name,
          type: type,
          key: selectedCustomField.id,
          predicates: predicates
        });
        if (selectedCustomField.options) {
          customField.options = selectedCustomField.options;
        }
        this.set('filterConditionBuffer.field.predicates', predicates);
        var predicateOptions = this.get('predicateOptions');
        if (Ember.isPresent(predicateOptions)) {
          this.set('filterConditionBuffer.predicate', predicateOptions.get('firstObject'));
        }
        this.setActionDetailsChoices.perform(this.get('filterConditionBuffer.field')).then(function () {
          if (Ember.isPresent(selectedCustomField.options)) {
            _this2.get('filterConditionBuffer.field.choices').unshiftObject({
              groupName: "".concat(selectedCustomField.name, " Choices"),
              options: selectedCustomField.options.map(function (option) {
                option.label = option.value;
                return option;
              })
            });
          }
        });
      }
    }
  });
});