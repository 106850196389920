define("workflows-web/components/dynamic-field/input-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NbSzhuic",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"hf-u-position-relative\"],[15,\"data-label\",[30,[36,2],[[35,1,[\"label\"]],[35,1,[\"uniqueId\"]]],null]],[12],[2,\"\\n  \"],[1,[30,[36,8],null,[[\"options\",\"value\",\"valueUpdated\"],[[30,[36,7],null,[[\"lineNumbers\",\"lineWrapping\",\"mode\",\"readOnly\",\"smartIndent\",\"styleActiveLine\",\"autofocus\"],[false,true,[35,6],[35,5],true,true,true]]],[35,4],[30,[36,3],[[32,0],\"onValueChange\"],null]]]]],[2,\"\\n\"],[6,[37,10],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"hf-dynamic-field_input-placeholder\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"placeholderText\",\"field\",\"or\",\"action\",\"fieldValue\",\"readOnly\",\"mode\",\"hash\",\"ivy-codemirror\",\"showPlaceholder\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/components/dynamic-field/input-component/template.hbs"
    }
  });
});