define("workflows-web/components/async-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qIwKO/Vo",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[35,2]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[1,[30,[36,3],[[35,6],[35,5],[35,4]],null]],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[35,0]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"svg\",\"inline-svg\",\"beforeTextSvg\",\"if\",\"text\",\"inProgressText\",\"inProgress\"]}",
    "meta": {
      "moduleName": "workflows-web/components/async-button/template.hbs"
    }
  });
});