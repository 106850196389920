define("workflows-web/components/file-upload/component", ["exports", "workflows-web/mixins/attach-files"], function (_exports, _attachFiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_attachFiles.default, {
    classNames: ['hf-file-upload'],
    hideInput: false,
    dataTestId: '',
    isDisabled: false,
    click: function click(event) {
      // setting up to null to clear the last selected input-file value cache.
      // Usecase Fix: File Attachment works only once - When adding an update if the attached file is removed and again trying to add an attachment.
      event.target.value = null;
      if (this.get('hideInput')) {
        // No point in having click event if the file input is visible anyway
        var inputElement = document.querySelector('input[type="file"]');
        if (inputElement && !inputElement.contains(event.target)) {
          inputElement.click();
        }
      }
    },
    change: function change(event) {
      var attachedFiles = event.target.files;
      var isSuccess = this.handleFileAttachment(attachedFiles);
      if (isSuccess && this.onChange) {
        this.onChange(attachedFiles);
      }
    }
  });
});