define("workflows-web/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "RUvFRYxd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"hf-upload-file-container \",[30,[36,5],[[35,8],\"hf-u-hide\"],null],\" \",[30,[36,5],[[35,7],\"hf-editor-maximized\"],null],\" \",[30,[36,5],[[35,6],\"hf-mod-disabled\"],null]]]],[12],[2,\"\\n\"],[6,[37,9],[[35,6]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[35,3],1],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"input\"],[15,\"accept\",[34,0]],[14,\"tabindex\",\"-1\"],[15,\"data-test-id\",[30,[36,2],[[35,1],\"-value\"],null]],[14,4,\"file\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"input\"],[15,\"accept\",[34,0]],[14,\"multiple\",\"\"],[14,\"tabindex\",\"-1\"],[15,\"data-test-id\",[30,[36,2],[[35,1],\"-value\"],null]],[14,4,\"file\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"acceptedFormats\",\"dataTestId\",\"concat\",\"maxCount\",\"eq\",\"if\",\"isDisabled\",\"isEditorMaximized\",\"hideInput\",\"unless\"]}",
    "meta": {
      "moduleName": "workflows-web/components/file-upload/template.hbs"
    }
  });
});