define("workflows-web/components/form-field-input/dynamic/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "nwqrU60d",
    "block": "{\"symbols\":[\"error\",\"field\"],\"statements\":[[10,\"div\"],[15,\"data-test-id\",[34,1]],[12],[2,\"\\n\"],[6,[37,11],null,[[\"refreshActionDetails\",\"isDynamicFieldActionDetailLoading\",\"field\",\"actionOnOpen\",\"onSearch\",\"loadMore\",\"onValueChange\",\"onFocusOut\",\"customFieldData\"],[[35,10],[35,9],[35,5],[30,[36,7],[[35,5,[\"actionOnOpen\"]],[35,8]],null],[30,[36,7],[[35,5,[\"onSearch\"]],[35,6]],null],[35,5,[\"loadMore\"]],[35,4],[35,3],[35,2]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[32,2,[\"input\"]]],[2,\"\\n\"],[6,[37,0],[[32,2,[\"isOpen\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[32,2,[\"dropdown\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[6,[37,13],[[30,[36,12],[[30,[36,12],[[35,5,[\"errors\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"hf-validation-error-message hf-validation-error-message-font\"],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"fieldDataTestId\",\"customFieldData\",\"onFocusOut\",\"onValueChange\",\"field\",\"onSearch\",\"or\",\"actionOnOpen\",\"isDynamicFieldActionDetailLoading\",\"refreshActionDetails\",\"dynamic-field\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "workflows-web/components/form-field-input/dynamic/template.hbs"
    }
  });
});