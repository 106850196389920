define("workflows-web/components/dynamic-field/dropdown-component/option-value/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pGUKAvj/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"hf-u-flex-container dynamic-field-option-container \",[30,[36,2],[[35,1],\"option-container-expanded\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-dynamic-field_dropdown-option-label\"],[15,\"data-test-id\",[30,[36,10],[\"dynamic-field-option-label-\",[30,[36,9],[[30,[36,8],[[35,4],[35,7]],null]],null]],null]],[12],[2,\"\\n\"],[6,[37,2],[[35,4,[\"prefix\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"hf-workflow-choices_options-prefix\"],[15,5,[30,[36,6],[[35,4,[\"prefix\"]]],null]],[12],[1,[35,4,[\"prefix\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[1,[30,[36,8],[[35,4],[35,7]],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"hf-dynamic-field_dropdown-option-value\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,12],[[30,[36,11],[[30,[36,5],[[35,4],[35,3]],null]],null],25],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,5],[[35,4],[35,3]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,5],[[35,4],[35,3]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,5],[[35,4],[35,3]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,14],[[35,1],[35,13]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,1,\"expand-button\"],[24,0,\"expand-button\"],[4,[38,0],[[32,0],\"toggleExpanded\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n      \"],[1,[30,[36,2],[[35,1],\"Less\",\"More\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"isExpanded\",\"if\",\"choiceFieldValueProperty\",\"choice\",\"field-to-string\",\"get-prefix-color\",\"choiceLabelProperty\",\"get\",\"convert-to-test-id\",\"concat\",\"length-of\",\"gt\",\"hasOverflow\",\"or\"]}",
    "meta": {
      "moduleName": "workflows-web/components/dynamic-field/dropdown-component/option-value/template.hbs"
    }
  });
});