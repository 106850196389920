define("workflows-web/mixins/attach-files", ["exports", "workflows-web/utilities/file", "workflows-web/constants"], function (_exports, _file, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    files: Ember.A(),
    acceptedFormats: '',
    excludedFormats: '',
    maxSize: null,
    maxCount: null,
    filesTooLargeMessage: '',
    maxCountExceededMessage: '',
    handleFileAttachment: function handleFileAttachment(attachedFiles) {
      var presentFiles = Ember.A(this.get('files'));
      var excludedFormats = this.get('excludedFormats');
      if (!(attachedFiles instanceof Array)) {
        attachedFiles = (0, _file.getFilesArrayFromFileList)(attachedFiles);
      }
      if (Ember.isPresent(excludedFormats)) {
        attachedFiles = attachedFiles.reject(function (file) {
          var type = file.type;
          type = type.slice(type.indexOf('/') + 1, type.length);
          return excludedFormats.includes(type.toLowerCase());
        });
      }
      var totalFiles;
      if (Ember.isPresent(presentFiles)) {
        totalFiles = (0, _file.mergeFilesArray)(presentFiles, attachedFiles);
      } else {
        totalFiles = attachedFiles;
      }
      if (this.validateFileSize(totalFiles) && this.validateFileCount(totalFiles) && this.validateFileFormat(attachedFiles)) {
        this.set('files', totalFiles);
        return true;
      }
      return false;
    },
    validateFileCount: function validateFileCount(totalFiles) {
      var maxCount = this.get('maxCount');
      if (Ember.isPresent(maxCount) && totalFiles.length > maxCount) {
        this.get('toastMessageService').showMessage({
          type: 'failure',
          text: this.get('maxCountExceededMessage')
        });
        return false;
      }
      return true;
    },
    validateFileSize: function validateFileSize(totalFiles) {
      var totalSize = (0, _file.getTotalSizeOfFiles)(totalFiles);
      var maxSize = this.get('maxSize');
      if (Ember.isPresent(maxSize)) {
        if (totalSize >= maxSize) {
          this.get('toastMessageService').showMessage({
            type: 'failure',
            text: this.get('filesTooLargeMessage') || 'file-upload.files-too-large'
          });
          return false;
        }
      }
      return true;
    },
    validateFileFormat: function validateFileFormat(attachedFiles) {
      var acceptedFormats = this.get('acceptedFormats');
      var areFilesSupported = true;
      if (acceptedFormats) {
        areFilesSupported = false;
        var fileFormatsToCheckAgainst = acceptedFormats;
        if (acceptedFormats === 'image/*') {
          fileFormatsToCheckAgainst = _constants.SUPPORTED_IMAGE_FORMATS;
        }
        for (var i = 0; i < attachedFiles.length; i++) {
          var mimeType = attachedFiles[i].type;
          var fileType = mimeType.slice(mimeType.indexOf('/') + 1, mimeType.length);
          if (fileFormatsToCheckAgainst.indexOf(fileType.toLowerCase()) !== -1 || fileFormatsToCheckAgainst.indexOf(mimeType.toLowerCase()) !== -1) {
            areFilesSupported = true;
            break;
          }
        }
      }
      if (!areFilesSupported) {
        this.get('toastMessageService').showMessage({
          type: 'failure',
          text: 'drop-zone.unsupported-file-type'
        });
      }
      return areFilesSupported;
    }
  });
});