define("workflows-web/initializers/modify-power-select", ["exports", "ember-power-select/components/power-select"], function (_exports, _powerSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'modify-power-select',
    initialize: function initialize() {
      // Powerselect is a tagless component, but we need to pass classes to it. Refer https://ember-power-select.com/docs/api-reference/
      _powerSelect.default.reopen({
        tagName: 'div',
        onTriggerFocus: function onTriggerFocus(_, event) {
          if (!this.get('isDestroyed') || !this.get('isDestroying')) {
            this.send('activate');
            var action = this.get('onfocus');
            if (action) {
              action(this.get('publicAPI'), event);
            }
          }
        },
        onFocus: function onFocus(event) {
          if (!this.get('isDestroyed') || !this.get('isDestroying')) {
            this.send('activate');
            var action = this.get('onfocus');
            if (action) {
              action(this.get('publicAPI'), event);
            }
          }
        },
        actions: {
          search: function search(term) {
            if (this.isDestroying) {
              return;
            }
            if (this.search) {
              this._performSearch(term);
            } else {
              if (Ember.isBlank(term)) {
                this._resetSearch();
              } else {
                this._performFilter(term);
              }
            }
          },
          /**
           * We have overridden the "select" action. 
           * When the user selects a value from the dropdown and selects the same value again,
           * we need to call the onChange action.
           */
          select: function select(selected, e) {
            var publicAPI = this.get('publicAPI');
            this.get('onchange')(selected, publicAPI, e);
          }
        }
      });
    }
  };
});