define("workflows-web/utilities/file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getFilesArrayFromFileList = getFilesArrayFromFileList;
  _exports.getTotalSizeOfFiles = getTotalSizeOfFiles;
  _exports.mergeFilesArray = mergeFilesArray;
  function mergeFilesArray(arr1, arr2) {
    var array1 = Object.keys(arr1).map(function (key) {
      return arr1[key];
    });
    var array2 = Object.keys(arr2).map(function (key) {
      return arr2[key];
    });
    return array1.concat(array2);
  }
  function getTotalSizeOfFiles(files) {
    var totalSize = 0;
    if (files) {
      files.forEach(function (file) {
        totalSize = totalSize + file.size;
      });
      return totalSize;
    }
  }
  function getFilesArrayFromFileList() {
    var files = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    // This will return an array of files from a FileList Object.
    var filesArray = Ember.A();
    for (var i = 0; i < files.length; i++) {
      // FileList object has length property.
      filesArray.pushObject(files[i]);
    }
    return filesArray;
  }
});