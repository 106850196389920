define("workflows-web/helpers/length-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function (params) {
    if (Ember.isPresent(params[0])) {
      return params[0].length;
    }
    return 0;
  });
});