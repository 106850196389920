define("workflows-web/components/insert-tags/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "iP/wwTHz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"hf-insert-merge-fields \",[30,[36,6],[[35,2],\"open\"],null]]]],[15,\"data-label\",[34,4,[\"label\"]]],[12],[2,\"\\n  \"],[11,\"div\"],[16,1,[30,[36,7],[\"insert-merge-field-\",[35,4,[\"label\"]]],null]],[24,0,\"hf-mini-pop-over_item hf-u-link-color\"],[16,\"data-test-id\",[34,8]],[4,[38,0],[[32,0],\"toggleDropdown\"],null],[12],[1,[30,[36,9],[\"insert-tags\"],null]],[13],[2,\"\\n\"],[6,[37,6],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,5],null,[[\"field\",\"choices\",\"isOpen\",\"isDynamicFieldActionDetailLoading\",\"closeDropdown\",\"updateFieldValue\",\"refreshActionDetails\"],[[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"closeDropdown\"],null],[30,[36,0],[[32,0],\"updateFieldValue\"],null],[30,[36,0],[[32,0],\"refreshActionDetails\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"isDynamicFieldActionDetailLoading\",\"isOpen\",\"choices\",\"field\",\"dynamic-field/dropdown-component\",\"if\",\"concat\",\"dataTestId\",\"t\"]}",
    "meta": {
      "moduleName": "workflows-web/components/insert-tags/template.hbs"
    }
  });
});