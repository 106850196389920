define("workflows-web/form-field-models/base-field", ["exports", "uuid"], function (_exports, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = Ember.Object.extend({
    intlService: Ember.inject.service('intl'),
    key: null,
    label: null,
    value: null,
    defaultValue: null,
    isMandatory: false,
    errors: null,
    allowWhiteSpace: false,
    isDisabled: false,
    _cleanValue: null,
    _initialValue: null,
    usePopOverTooltipForHelpText: false,
    init: function init() {
      this.setProperties({
        _initialValue: this.get('value'),
        errors: Ember.A(),
        uniqueId: (0, _uuid.v4)()
      });
      var defaultValue = this.get('defaultValue');
      if (Ember.isPresent(defaultValue) && !this.get('value')) {
        this.setProperties({
          value: defaultValue,
          _initialValue: defaultValue
        });
      }
    },
    hasErrors: Ember.computed.notEmpty('errors'),
    isDirty: Ember.computed('value', '_initialValue', {
      get: function get() {
        return !_.isEqual(this.get('_initialValue'), this.get('value'));
      }
    }),
    hasValueIfMandatory: Ember.computed('isMandatory', 'value.[]', {
      get: function get() {
        return !this.get('isMandatory') || Ember.isPresent(this.get('value'));
      }
    }),
    _initializeErrors: function _initializeErrors() {
      this.set('errors', Ember.A());
    },
    _isValueBlank: function _isValueBlank() {
      return Ember.isBlank(this.get('value'));
    },
    _isValueEmpty: function _isValueEmpty() {
      return Ember.isEmpty(this.get('value'));
    },
    addError: function addError(error) {
      this.get('errors').addObject(error);
    },
    // Will be useful to add errors for child fields of object and array fields
    addMultipleErrors: function addMultipleErrors(errors) {
      this.get('errors').addObjects(errors);
    },
    updateInitialValue: function updateInitialValue() {
      this.set('_initialValue', this.get('value'));
    },
    clearInitialValue: function clearInitialValue() {
      this.set('_initialValue', null);
    },
    resetErrors: function resetErrors() {
      this._initializeErrors();
    },
    reset: function reset() {
      this.set('errors', Ember.A());
      this.set('value', this.get('_initialValue'));
    },
    validate: function validate() {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this._initializeErrors();
        // Mandatory check. isBlank is used as it doesn't invalidate false, which is needed.
        if (_this.get('isMandatory')) {
          // Password contain space, so we need to use empty instead of blank.
          var hasNoFilledValue = _this.get('allowWhiteSpace') ? _this._isValueEmpty() : _this._isValueBlank();
          // issue with isEmpty: https://github.com/emberjs/ember.js/issues/18875, affects when value is []
          if (_typeof(_this.get('value')) === 'object') {
            // which means some value is present
            hasNoFilledValue = false;
          }
          if (hasNoFilledValue) {
            _this.addError(_this.get('intlService').findTranslationByKey('validation.mandatory-error-message'));
            reject();
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    },
    getCleanValue: function getCleanValue() {
      return this.get('_cleanValue');
    },
    setErrors: function setErrors(errors) {
      this.set('errors', errors);
    },
    clear: function clear() {
      this.setProperties({
        value: null,
        _initialValue: null
      });
    }
  });
});